import {
  IHeaderOption,
  IHeaderOptions,
  IHeaderSubgroup,
  ITabBarSection,
  IMenuMobile,
} from "@/models/HousfyPage";
import { MenuOptionsIds, TabbarIds, MenuMobileIds } from "@/enums/HousfyPage";
import { resolveRoutePath } from "@/router";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

const MENU_OPTIONS_DESKTOP: IHeaderSubgroup[] = [
  {
    label: "common.yourAccount",
    subitems: [
      {
        id: MenuOptionsIds.ACCOUNT,
        icon: "icons/gear",
        label: "common.configuration",
        link: resolveRoutePath(MYHExternalRoutes.profile()),
        tracking: "link_header_menu_account-settings",
        devices: ["desktop"],
      },
      {
        id: MenuOptionsIds.FRIEND,
        icon: "icons/gift",
        label: "common.inviteAFriend",
        link: resolveRoutePath(MYHExternalRoutes.referralFriend()),
        tracking: "link_header_menu_referral-friend",
        devices: ["desktop"],
      },
    ],
  },
  {
    label: "common.customerService",
    subitems: [
      {
        id: MenuOptionsIds.SUGGESTIONS,
        label: "common.suggestionsAndComplaints",
        icon: "icons/interrogation-circle",
        link: resolveRoutePath(MYHExternalRoutes.help()),
        tracking: "link_header_menu_suggestions",
        devices: ["desktop"],
      },
      {
        id: MenuOptionsIds.CONTACT,
        icon: "icons/phone",
        label: "common.contactHousfy",
        link: resolveRoutePath(MYHExternalRoutes.contact()),
        tracking: "link_header_menu_contact",
        devices: ["desktop"],
      },
    ],
  },
  {
    subitems: [
      {
        id: MenuOptionsIds.LOGOUT,
        label: "common.logout",
        icon: "icons/logout",
        link: resolveRoutePath(MYHExternalRoutes.logout()),
        tracking: "link_header_menu_logout",
        devices: ["mobile", "desktop"],
      },
    ],
  },
];

const MENU_OPTIONS_MOBILE: IHeaderSubgroup[] = [
  {
    subitems: [
      {
        id: MenuOptionsIds.ACTIVITY,
        label: "common.yourActivity",
        link: resolveRoutePath(MYHExternalRoutes.myhHome()),
        icon: "icons/homeTick",
        tracking: "link_header_menu_your-activity",
        devices: ["mobile"],
      },
      {
        id: MenuOptionsIds.TOOLS,
        label: "common.tools",
        link: resolveRoutePath(MYHExternalRoutes.tools()),
        icon: "icons/tools",
        tracking: "link_header_menu_tools",
        devices: ["mobile"],
      },
      {
        id: MenuOptionsIds.MENU,
        label: "common.menu",
        icon: "icons/menu",
        tracking: "link_header_menu_menu",
        devices: ["mobile"],
      },
    ],
  },
  {
    subitems: [
      {
        id: MenuOptionsIds.LOGOUT,
        label: "common.logout",
        icon: "icons/logout",
        link: resolveRoutePath(MYHExternalRoutes.logout()),
        tracking: "link_header_menu_logout",
        devices: ["mobile", "desktop"],
      },
    ],
  },
];

const HORIZONTAL_OPTIONS: IHeaderOption[] = [
  {
    id: MenuOptionsIds.FRIEND,
    label: "common.win200euros",
    link: resolveRoutePath(MYHExternalRoutes.referralFriend()),
    icon: "icons/gift",
    devices: ["mobile", "desktop"],
    tracking: "link_header_main_referral",
  },
];

export const HEADER_OPTIONS: IHeaderOptions = {
  menuOptionsMobile: MENU_OPTIONS_MOBILE,
  menuOptionsDesktop: MENU_OPTIONS_DESKTOP,
  horizontalOptions: HORIZONTAL_OPTIONS,
};

export const MAIN_LOGO: IHeaderOption = {
  label: "Housfy",
  link: resolveRoutePath(MYHExternalRoutes.home()),
  icon: "logos/housfy",
  tracking: "link_header_main_logo",
  devices: ["mobile", "desktop"],
};

export const TAB_BAR_ITEMS: ITabBarSection[] = [
  {
    activity: {
      id: TabbarIds.ACTIVITY,
      icon: "icons/house_check",
      label: "common.yourActivity",
      link: resolveRoutePath(MYHExternalRoutes.myhHome()),
      tracking: "link_tabbar_your-activity",
      devices: ["mobile", "desktop"],
    },
    tools: {
      id: TabbarIds.TOOLS,
      icon: "icons/squares",
      label: "common.tools",
      link: resolveRoutePath(MYHExternalRoutes.tools()),
      tracking: "link_tabbar_tools",
      devices: ["mobile", "desktop"],
    },
    menu: {
      id: TabbarIds.MENU,
      icon: "icons/hamburguer",
      label: "",
      tracking: "link_tabbar_menu",
      devices: ["mobile"],
    },
  },
  {
    visits: {
      id: TabbarIds.VISITS,
      icon: "icons/calendar",
      label: "common.visitsCalendar",
      link: resolveRoutePath(MYHExternalRoutes.visits()),
      tracking: "link_tabbar_visits-calendar",
      devices: ["desktop"],
    },
    documents: {
      id: TabbarIds.DOCUMENTS,
      icon: "icons/folder",
      label: "common.documents",
      link: resolveRoutePath(MYHExternalRoutes.documents()),
      tracking: "link_tabbar_documents",
      devices: ["desktop"],
    },
    favorites: {
      id: TabbarIds.FAVORITES,
      icon: "icons/heart",
      label: "common.yourFavorites",
      link: resolveRoutePath(MYHExternalRoutes.favorites()),
      tracking: "link_tabbar_your-favorites",
      devices: ["desktop"],
    },
    searches: {
      id: TabbarIds.SEARCHES,
      icon: "icons/bookmark",
      label: "common.savedSearches",
      link: resolveRoutePath(MYHExternalRoutes.savedSearches()),
      tracking: "link_tabbar_saved-searches",
      devices: ["desktop"],
    },
  },
];

export const MENU_MOBILE: IMenuMobile = {
  title: "common.menu",
  tabs: [
    {
      subitems: [
        {
          id: MenuMobileIds.VISITS,
          icon: "icons/calendar",
          label: "common.calendarOfVisits",
          link: resolveRoutePath(MYHExternalRoutes.visits()),
          tracking: "link_menu-mobile_visits-calendar",
        },
        {
          id: MenuMobileIds.DOCUMENTS,
          icon: "icons/folder",
          label: "common.documents",
          link: resolveRoutePath(MYHExternalRoutes.documents()),
          tracking: "link_menu-mobile_documents",
        },
        {
          id: MenuMobileIds.FAVORITES,
          icon: "icons/heart",
          label: "common.yourFavorites",
          link: resolveRoutePath(MYHExternalRoutes.favorites()),
          tracking: "link_menu-mobile_your-favorites",
        },
        {
          id: MenuMobileIds.SEARCHES,
          icon: "icons/bookmark",
          label: "common.savedSearches",
          link: resolveRoutePath(MYHExternalRoutes.savedSearches()),
          tracking: "link_menu-mobile_saved-searches",
        },
      ],
    },
    {
      label: "common.yourAccount",
      subitems: [
        {
          id: MenuMobileIds.ACCOUNT,
          icon: "icons/gear",
          label: "common.accountSettings",
          link: resolveRoutePath(MYHExternalRoutes.profile()),
          tracking: "link_menu-mobile_account-settings",
        },
        {
          id: MenuMobileIds.FRIEND,
          icon: "icons/gift",
          label: "common.inviteAFriend",
          link: resolveRoutePath(MYHExternalRoutes.referralFriend()),
          tracking: "link_menu-mobile_referral-friend",
        },
      ],
    },
    {
      label: "common.customerService",
      subitems: [
        {
          id: MenuMobileIds.SUGGESTIONS,
          icon: "icons/interrogation-circle",
          label: "common.suggestionsAndComplaints",
          link: resolveRoutePath(MYHExternalRoutes.help()),
          tracking: "link_menu-mobile_help",
        },
        {
          id: MenuMobileIds.CONTACT,
          icon: "icons/phone",
          label: "common.contactWithHousfy",
          link: resolveRoutePath(MYHExternalRoutes.contact()),
          tracking: "link_menu-mobile_contact",
        },
      ],
    },
  ],
  cta: {
    label: "common.logout",
    link: resolveRoutePath(MYHExternalRoutes.logout()),
    tracking: "button_menu-mobile_logout",
  },
  footer: [
    {
      label: "common.termsAndConditions",
      link: resolveRoutePath(MYHExternalRoutes.terms()),
      tracking: "link_menu-mobile_terms-conditions",
    },
    {
      label: "common.withdrawal",
      link: resolveRoutePath(MYHExternalRoutes.withdrawal()),
      tracking: "link_menu-mobile_withdrawal",
    },
    {
      label: "common.privacyPolicy",
      link: resolveRoutePath(MYHExternalRoutes.privacyUsers()),
      tracking: "link_menu-mobile_privacy-policy",
    },
    {
      label: "common.cookies",
      link: resolveRoutePath(MYHExternalRoutes.cookies()),
      tracking: "link_menu-mobile_cookies",
    },
  ],
};
