/* eslint-disable no-unused-vars */

export enum MenuOptionsIds {
  ACTIVITY = "activity",
  TOOLS = "tools",
  MENU = "menu",
  LOGOUT = "logout",
  ACCOUNT = "account",
  FRIEND = "friend",
  CONTACT = "contact",
  SUGGESTIONS = "suggestions",
}

export enum TabbarIds {
  ACTIVITY = "activity",
  TOOLS = "tools",
  MENU = "menu",
  VISITS = "visits",
  DOCUMENTS = "documents",
  FAVORITES = "favorites",
  SEARCHES = "searches",
}

export enum MenuMobileIds {
  VISITS = "visits",
  DOCUMENTS = "documents",
  FAVORITES = "favorites",
  SEARCHES = "searches",
  ACCOUNT = "account",
  FRIEND = "friend",
  CONTACT = "contact",
  SUGGESTIONS = "suggestions",
}
